import { createSlice } from "@reduxjs/toolkit";

const blogSlice = createSlice({
  name: "loader",
  initialState: false,
  reducers: {
    loaded(state, payload) {
      return payload.payload;
    },
  },
});

export const { loaded } = blogSlice.actions;

export default blogSlice.reducer;
