import React, { useState } from "react";
import className from "classnames";

import CommentForm from "../CommentForm";
import {
  apiCallerPost,
  formatElapsedTime,
  parseItemImage,
  sendWoogiEvent,
} from "../../../../services/api";

export default function Replies({ comments: old, post, commentId }) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [comments, setComments] = useState(old);

  const onSubmit = async () => {
    setLoading(true);

    const { data } = await apiCallerPost({
      cmd: "auth_HeroLeagueBlogOpen.addComment",
      pid: +commentId,
      content: value,
      blog_id: +post.id,
    });

    setComments((old) => [data, ...comments]);

    sendWoogiEvent("EVENT_ADD_COMMENT");

    setValue("");
    setLoading(false);
  };

  return (
    <div>
      <div className="w-full flex flex-row-reverse">
        <button
          onClick={() => setShowReplies((old) => !old)}
          className="flex flex-row justify-center items-center text-black"
        >
          <span
            className="w-7 h-7 bg-cover mr-2 hover:w-8 hover:h-8 transition-all duration-200"
            style={{ backgroundImage: "url(/images/feedback.png)" }}
          />
          <span className="text-2xl">{comments?.length}</span>
        </button>
      </div>

      {showReplies && (
        <div className="rounded mt-5 bg-blue-50 pb-4 shadow">
          {!loading &&
            comments?.map((reply) => (
              <div
                key={reply.add_time}
                className={className("comment flex flex-row", {
                  "pending-comment": reply?.enable == 0,
                })}
              >
                <div className="commenter-info">
                  <div className="commentor-imgContainer">
                    <img
                      src={parseItemImage(reply.avatar)}
                      className="commentor-img"
                      alt={reply.username}
                    />
                  </div>
                </div>
                <div className="commentData">
                  <div className="flex flex-row justify-between">
                    <h3 className="commentor-name">{reply.username}</h3>
                    {reply?.enable == 0 && (
                      <span className=" font-sans font-medium text-black/60 italic">
                        Pending Approval
                      </span>
                    )}
                  </div>
                  <span className="comment-time">
                    {" "}
                    {formatElapsedTime(+reply.add_time)}
                  </span>
                  <p className="actual-comment">{reply.content}</p>
                </div>
              </div>
            ))}
          <CommentForm
            btnClasses="ml-3"
            value={value}
            onChange={setValue}
            loading={loading}
            onSubmit={onSubmit}
          />
        </div>
      )}
    </div>
  );
}
