import React, { useState } from "react";
import { sendWoogiEvent } from "../../../services/api";
import { comicLink } from "../../../lib";

function ComicsModal({ onHide, portal }) {
  const [loading, setLoading] = useState(false);
  return (
    <div className="comic-page-container">
      <button
        className="w-14 h-14 bg-cover absolute top-3 right-4 z-50"
        onClick={() => onHide(null)}
        style={{
          background: "url(images/close.png)",
          backgroundSize: "cover",
        }}
      />
      {loading && <div className="loading-spinner z-50"></div>}
      <iframe
        onLoad={() => {
          setLoading(false);
          sendWoogiEvent("EVENT_READ_COMIC");
        }}
        title="comic"
        style={{
          width: "100%",
          height: "100%",
          background: "transparent",
          position: "absolute",
        }}
        src={comicLink(portal)}
      />
    </div>
  );
}

export default ComicsModal;
