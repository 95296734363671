import React, { useState } from "react";

import { useSelector } from "react-redux";
import { getWhatsNew } from "../../../../store/slices/WhatsNew";

import "./WhatsNew.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WhatsNew = () => {
  const [sliderRef, setSliderRef] = useState(null);
  const news = useSelector(getWhatsNew);

  // slider settings
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    lazyLoad: "ondemand",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "20px",
  };

  return (
    <div className="news-container">
      <div class="head-container">
        <div className="news-head">
          <p className="charcter-name">What's New?</p>
        </div>
      </div>
      <div className="slider-wrap">
        <div className="left-arrow-container">
          <button onClick={sliderRef?.slickPrev}>
            <img src="images/icon-back.png" alt="Back Arrow" />
          </button>
        </div>
        <Slider dots ref={setSliderRef} {...settings}>
          {news?.map((newsData, index) => {
            return (
              <div
                className="news-slide"
                key={index}
                dangerouslySetInnerHTML={{ __html: newsData.content }}
              />
            );
          })}
        </Slider>
        <div className="right-arrow-container">
          <button onClick={sliderRef?.slickNext}>
            <img src="images/icon-next.png" alt="Next Arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhatsNew;
