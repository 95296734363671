/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import FeedbackForm from "./FeedbackForm";
import CoinsContent from "./CoinsContent";
import { apiCallerPost } from "../../../../../services/api";
import "./NewModal.css";
import { useSelector } from "react-redux";
import { getUser } from "../../../../../store/slices/user";

export default function NewModal({ dismiss }) {
  const [type, setType] = useState("form");
  const auth = useSelector(getUser);
  const [coins, setCoins] = useState(0);

  const [inputData, setInputData] = useState({
    details: "",
    email: "",
  });

  const submitFeedback = async () => {
    try {
      if (inputData.details === "") {
        return window.alert("Please add feedback");
      }

      const postData = {
        ...inputData,
        token: auth.access_token,
        cmd: "AdminData.addBugsReport1",
      };

      setType("loading");

      const resp = await apiCallerPost(postData);

      setInputData({
        details: "",
        email: "",
      });

      if (resp.data?.add_coins > 0) {
        setCoins(resp.data.add_coins);
        setType("coins");
      } else {
        setType("thanks");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="frame-container"
    >
      <img src={require("./HL_UI-BgCircle-lg.png")} className="left-circles" />
      <img src={require("./HL_UI-BgCircle-lg.png")} className="right-circles" />
      <div className="frame">
        <div className="top-area">
          <div className="title bg-cover">
            <h1>Feedback</h1>
          </div>

          <img
            onClick={dismiss}
            className="close-png cursor-pointer"
            src="images/close.png"
          />
        </div>

        <div className="frame-body bg-white h-full">
          {type === "form" && (
            <FeedbackForm value={inputData} onInputData={setInputData} />
          )}
          {type === "coins" && <CoinsContent coins={coins} />}
          {type === "loading" && <div className="loading-spinner" />}
          {type == "thanks" && (
            <div className="w-full h-full flex justify-center items-center">
              <p className="frame-content">
                Thanks for your feedback! You are helping make Heroic Game Day
                even more better. If you find a major bug, you can win bigger
                prizes!
              </p>
            </div>
          )}
        </div>

        <div className="bottom-area">
          <button
            className="btn cursor-pointer"
            onClick={
              type === "form"
                ? submitFeedback
                : type === "coins" || type === "thanks"
                ? dismiss
                : () => {}
            }
          >
            {type === "form"
              ? "Send"
              : type === "coins" || type === "thanks"
              ? "Okay"
              : "Loading..."}
          </button>
        </div>
      </div>
    </motion.div>
  );
}
