import React, { useState } from "react";
import "./Comics.css";
import MobileHeader from "../MobileHeader/MobileHeader";
import PageTitle from "../PageTitle/PageTitle";

import { useSelector } from "react-redux";
import { getComics } from "../../../store/slices/comics";
import ReadMoreButton from "../ReadMoreButton/ReadMoreButton";

import { useEffect } from "react";
import { sendWoogiEvent } from "../../../services/api";

import { useContext } from "react";
import { GlobalInfo } from "../../../App";

const Comics = () => {
  const comics = useSelector(getComics);
  const { setComicsPortal } = useContext(GlobalInfo);

  const openPortal = (comic) => {
    setComicsPortal(comic);
    sendWoogiEvent("EVENT_OPEN_COMIC");
  };

  useEffect(() => {
    sendWoogiEvent("BUTTON_OPEN_COMIC_VAULT");
  }, []);

  return (
    <div className="comics-container">
      <MobileHeader />
      <PageTitle customColor="#FF8500" title="Comics" />

      <div className="comics-data-container">
        <h1 className="new-tag">New</h1>
        <div className="comics-card-container">
          {comics.length > 0 &&
            comics.slice(0, 3).map((comic, index) => (
              <div
                key={index}
                className="comics-card cursor-pointer"
                onClick={() => openPortal(comic)}
              >
                <img
                  className="comics-coverimg"
                  src={comic.cover_img}
                  alt={comic?.name}
                />
              </div>
            ))}
        </div>
        <ReadMoreButton
          link="/comics-list"
          text="Read More"
          className="read-more-btntext"
          parentClass={"readmore-comics-btn"}
        />
      </div>
    </div>
  );
};

export default Comics;
