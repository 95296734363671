import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/slices/user";
import { loadUiData } from "../../store/slices/ui";
const openEye = "./images/open-eyes.png";
const closeEye = "./images/closed-eyes.png";

export default function LoginCredentialsModal() {
  const auth = useSelector(getUser);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const saveValueToClipboard = () => {
    const text = `Username: ${auth.user.login_name} \nPassword: ${auth.user.password}`;
    navigator.clipboard.writeText(text);

    // Alert the copied text
    alert("Copied the text: " + text);
  };

  const close = () => {
    dispatch(loadUiData(0));
  };

  return (
    <div className="flex flex-1 justify-center items-center absolute h-screen w-screen bg-blue-700/60 z-50">
      <div className="rounded relative w-96 p-6 shadow-2xl border-4 border-solid border-black bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">
        <button className="close" onClick={close} />
        <div className="rounded bg-white w-100 h-full p-4 text-center">
          <h1 className="text-4xl font-bold mb-1">Login Credentials</h1>
          <p className="text-xl mb-4">
            Write down your USERNAME and PASSWORD on your Heroic Pass to play
            over the Summer
          </p>
          <div className="mb-2">
            <label className="text-xl" htmlFor="username">
              Username
            </label>
            <input
              readOnly
              id="username"
              className="border-2 w-full h-10 px-2 rounded text-xl text-center font-acme"
              value={auth.user.login_name}
            />
          </div>
          <div className="mb-5">
            <label className="text-xl" htmlFor="password">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="border-2 w-full h-10 px-2 rounded text-xl text-center font-acme"
                value={auth.user.password}
              />
              <div
                className="w-6 absolute right-3 top-2"
                onClick={() => setShowPassword((old) => !old)}
              >
                <img
                  src={showPassword ? openEye : closeEye}
                  alt="show/hide password"
                />
              </div>
            </div>
          </div>

          <button
            onClick={saveValueToClipboard}
            className="copy-to-clipboard-btn "
          >
            Copy to clipboard
          </button>
        </div>
      </div>
    </div>
  );
}
