import axios from "axios";

export const API_URL =
  process.env.NODE_ENV === "development"
    ? "https://portal.americau.com/woogi/0.1/actor/cgi.php"
    : "/woogi/0.1/actor/cgi.php";

export const apiCallerGet = (data) => axios.get(API_URL, data);

export const apiCallerPost = (data) => {
  const token = JSON.parse(localStorage.getItem("access_token"));
  const jwt = JSON.parse(localStorage.getItem("jwt_str"));

  return axios.post(
    API_URL,
    { token, ...data },
    {
      headers: { jwt },
    }
  );
};

export const apiCallerPostLogin = (data) => {
  return axios.post(API_URL, { ...data });
};

export function formatElapsedTime(add_time) {
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  const elapsedSeconds = currentTime - add_time;

  if (elapsedSeconds < 60) {
    return `Posted ${elapsedSeconds} seconds ago.`;
  } else if (elapsedSeconds < 3600) {
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const remainingSeconds = elapsedSeconds % 60;
    return `Posted ${elapsedMinutes} minute${
      elapsedMinutes > 1 ? "s" : ""
    } and ${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""} ago.`;
  } else if (elapsedSeconds < 86400) {
    const elapsedHours = Math.floor(elapsedSeconds / 3600);
    const remainingMinutes = Math.floor((elapsedSeconds % 3600) / 60);
    return `Posted ${elapsedHours} hour${
      elapsedHours > 1 ? "s" : ""
    } and ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""} ago.`;
  } else {
    const elapsedDays = Math.floor(elapsedSeconds / 86400);
    return `Posted ${elapsedDays} day${elapsedDays > 1 ? "s" : ""} ago.`;
  }
}

export function redirectToGame(event) {
  window.location.href = "/loading?scene=" + event; //relative to domain
}

export const imagesBase =
  process.env.NODE_ENV == "production"
    ? window.location.origin
    : "https://portal.americau.com";

export const parseItemImage = (url) => {
  if (process.env.NODE_ENV == "production") return url;

  return url
    ? url.includes("mainbackend")
      ? url
      : "//mainbackend.woogiu.com/" + url
    : "";
};

let portalLoginStatus = false;

export const sendWoogiEvent = async (eventName) => {
  if (!portalLoginStatus) {
    const formData = {
      cmd: "WoogiOAuth.portalLoginStatus",
    };

    let result = await apiCallerPostLogin(formData);

    if (result.data == 200) {
      portalLoginStatus = true;
    }
  }

  if (typeof window?.woogievent == "function" && portalLoginStatus) {
    window?.woogievent(eventName);
  }
};
