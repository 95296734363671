import React, { useEffect } from "react";
import BlogSlider from "./BlogSlider/BlogSlider";
import HomepageTabs from "./HomepageTabs/HomepageTabs";
import "./Home.css";
import MobileHeader from "../MobileHeader/MobileHeader";
import { useDispatch, useSelector } from "react-redux";
import { getSeasons } from "../../../store/slices/season";
import {
  apiCallerPost,
  imagesBase,
  sendWoogiEvent,
} from "../../../services/api";
import ReadMoreButton from "../ReadMoreButton/ReadMoreButton";
import { disbleModal, isGiftModalShown } from "../../../store/slices/gifts";
import LoginCredentialsModal from "../../Shared/LoginCredentialsModal";
import { getPasswordModalStatus, loadUiData } from "../../../store/slices/ui";
import user, { getUser } from "../../../store/slices/user";

const Home = () => {
  const season = useSelector(getSeasons);
  const isGiftModal = useSelector(isGiftModalShown);
  const passwordModal = useSelector(getPasswordModalStatus);
  const dispatch = useDispatch();
  const auth = useSelector(getUser);

  //hide gift box
  const handleHideGiftBox = async () => {
    try {
      const localUser = localStorage.getItem("user");
      const userId = JSON.parse(localUser)?.uid;

      const { data } = await apiCallerPost({
        cmd: "GetGifts.userDisableReceivedGiftModal",
        user_id: userId,
      });

      if (+data?.code === 200) {
        dispatch(disbleModal());
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     const userId = auth.user.id;
  //     if (userId) {
  //       const response = await apiCallerPost({
  //         cmd: "auth_HeroLeaguePortalIndex.showNameAndPwd",
  //         user_id: userId,
  //       });

  //       dispatch(
  //         loadUiData({
  //           passwordModal: response.data,
  //         })
  //       );

  //       console.log("User", userId, response.data);
  //     }
  //   })();
  // }, [auth.user.id, dispatch]);

  return (
    <React.Fragment>
      <div
        className="home-container"
        style={{ backgroundImage: `url(${imagesBase + "/" + season?.bg_img})` }}
      >
        <MobileHeader />
        <div className="row">
          <div className="season-box-wrap">
            <div className="season-box">
              <h1 className="season-heading">{season?.main_title}</h1>
              <p className="season-description">{season?.main_content}</p>
              <button
                onClick={() => {
                  if (window.top?.sw?.playnow) {
                    window.top.sw.playnow();
                  }

                  sendWoogiEvent("BUTTON_PLAY_NOW");
                }}
                className="btn ml-auto"
              >
                Play Now
              </button>
            </div>
            <div
              className="gift-wrap"
              style={{ display: isGiftModal ? "block" : "none" }}
            >
              <img
                onClick={handleHideGiftBox}
                className="gift"
                src="images/gift.png"
                alt="close"
              />
              <img
                onClick={handleHideGiftBox}
                className="close"
                src="images/close.png"
                alt="close"
              />
              <p>You Recived a gift in the game! Login to Check it out!</p>
            </div>
          </div>

          <HomepageTabs />
        </div>

        <div className="blog-row">
          <div className="heading-btn-container">
            <h1 className="latest-blogs-heading">Latest Blogs & Updates</h1>
            <ReadMoreButton
              link={"/blog"}
              className="read-more-btntext"
              text="Read More"
            />
          </div>

          {/* Blog Sliders */}
          <BlogSlider />
        </div>
      </div>

      {Boolean(passwordModal) && <LoginCredentialsModal />}
    </React.Fragment>
  );
};

export default Home;
