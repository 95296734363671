import React from "react";
import MobileHeader from "../MobileHeader/MobileHeader";
import PageTitle from "../PageTitle/PageTitle";
import "./ComicsListing.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getComics } from "../../../store/slices/comics";
import dayjs from "dayjs";
import { useState } from "react";
import { createPortal } from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import { sendWoogiEvent } from "../../../services/api";
import { useContext } from "react";
import { GlobalInfo } from "../../../App";

const PAGE_SIZE = 6;

const comicLink = (comic) => {
  const isDev = process.env.NODE_ENV === "development";

  return isDev
    ? // ? `https://portal.americau.com/comic/read?comic_id=${comic.id}`
      `https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe`
    : `/woogi/0.1/static/leaderboard/v3/comic/read.php?comic_id=${comic.id}`;
};

const ComicsListing = () => {
  const [page, setPage] = useState(1);
  const comics = useSelector(getComics);
  const { setComicsPortal } = useContext(GlobalInfo);

  const openPortal = (comic) => {
    setComicsPortal(comic);
    sendWoogiEvent("EVENT_OPEN_COMIC");
  };

  const totalPage = Math.ceil(comics.length / PAGE_SIZE);

  const onPrev = () => {
    if (page > 1) setPage(page - 1);
  };

  const onNext = () => {
    if (page < totalPage) setPage(page + 1);
  };

  return (
    <div className="comics-container">
      <MobileHeader />
      <PageTitle customColor="#FF8500" title="Comics" />
      <AnimatePresence>
        {comics
          .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
          .map((comic, index) => {
            return (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key={comic.id + index}
                className="comicsCard cursor-pointer"
                onClick={() => openPortal(comic)}
              >
                <div className="comics-img-container">
                  <img
                    className="comicsListing-thumbnail"
                    src={comic.cover_img}
                    alt="blog thumbnail"
                  />
                </div>
                <div className="comicsContent-container">
                  <Link className="comicsTitle-link" to="#">
                    <h1 className="comicsListing-title">
                      {comic.name ? comic.name : "Please add title"}
                    </h1>
                  </Link>
                  <p className="blog-date">
                    {dayjs(comic.c_time * 1000).format("MMMM DD,   YYYY")}{" "}
                  </p>
                  <p className="comics-description">
                    {comic.content ? comic.content : "Content Not Found"}
                  </p>
                </div>
              </motion.div>
            );
          })}
      </AnimatePresence>
      <div className="blog-pagintaion">
        {page != 1 && (
          <img
            onClick={onPrev}
            className="blog-left-arrow"
            src="images/blog-left-arrow.png"
            alt=""
          />
        )}
        {page < totalPage && (
          <img
            onClick={onNext}
            className="blog-right-arrow"
            src="images/blog-right-arrow.png"
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default ComicsListing;
