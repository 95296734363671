import { createSelector, createSlice } from "@reduxjs/toolkit";

const newsSlice = createSlice({
  name: "whatsnew",
  initialState: [],
  reducers: {
    loadWhatsnew(state, action) {
      return action.payload;
    },
    unloadWhatsnew(state, action) {
      return [];
    },
  },
});

export const { loadWhatsnew, unloadWhatsnew } = newsSlice.actions;

const whatsnew = (state) => state.whatsnew;
export const getWhatsNew = createSelector(whatsnew, (whatsnew) => whatsnew);
export default newsSlice.reducer;
