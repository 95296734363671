import React, { useState } from "react";
import "./HomepageTabs.css";
import ChatTab from "./ChatTab/ChatTab";
import FriendsList from "./FriendsList/FriendsList";
import MyHero from "./MyHero/MyHero";
import { createPortal } from "react-dom";
import NewModal from "./FeedbackModal/NewModal";

import WhatsNew from "../WhatsNew/WhatsNew";
import { AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";

import { logout } from "../../../../store/slices/user";
import { hardLogout } from "../../../../lib";
import { loaded } from "../../../../store/slices/loader";

const HomepageTabs = () => {
  const [selectBox, setSelectBox] = useState(1);
  const [feedbackModal, setFeedbackModal] = useState(false);

  const dispatch = useDispatch(logout);

  const logOutUser = async () => {
    hardLogout();
    dispatch(logout());
    dispatch(loaded(false));
  };

  return (
    <AnimatePresence>
      <div className="user-wrap">
        <div className="character-container">
          <div className="power-select-character">
            <MyHero MyHeroTabActive={selectBox} />
            <FriendsList friendsTabActive={selectBox} />
            <ChatTab chatTabActive={selectBox} />
          </div>
          <div
            onClick={() => setSelectBox(1)}
            style={
              selectBox === 1
                ? {
                    left: "20px",
                    top: "150px",
                    width: "120px",
                    height: "37px",
                    display: "none",
                  }
                : {}
            }
            className="myheroes-container"
          >
            <img
              alt=""
              src={
                selectBox === 1
                  ? "images/Portal_btnBlue.png"
                  : "images/Portal_btnOrange.png"
              }
              className="myheroes-img"
            />
            <h1 className="myheroes-text">My Hero</h1>
          </div>
          <div
            onClick={() => setSelectBox(2)}
            style={
              selectBox === 2
                ? {
                    left: "20px",
                    top: "193px",
                    width: "120px",
                    height: "37px",
                    display: "none",
                  }
                : { display: "none" }
            }
            className="myfriends-container"
          >
            <img
              src={
                selectBox === 2
                  ? "images/Portal_btnBlue.png"
                  : "images/Portal_btnOrange.png"
              }
              alt=""
              className="myheroes-img"
            />
            <h1 className="myheroes-text">Friends</h1>
          </div>
          <div
            onClick={() => setSelectBox(3)}
            style={
              selectBox === 3
                ? {
                    left: "20px",
                    top: "235px",
                    width: "120px",
                    height: "37px",
                    display: "none",
                  }
                : { display: "none" }
            }
            className="chat-container"
          >
            <img
              src={
                selectBox === 3
                  ? "images/Portal_btnBlue.png"
                  : "images/Portal_btnOrange.png"
              }
              alt=""
              className="myheroes-img"
            />
            <h1 className="myheroes-text">Chat</h1>
          </div>

          <div className="flex flex-row items-center mb-10 justify-between pr-2">
            <button
              className="feedback-btn"
              onClick={() => setFeedbackModal((old) => !old)}
            >
              Give Feedback
            </button>

            <button className="logout-link" onClick={logOutUser}>
              Logout
            </button>
          </div>
          {feedbackModal &&
            createPortal(
              <NewModal dismiss={() => setFeedbackModal(false)} />,
              document.body
            )}
        </div>

        <WhatsNew />
      </div>
    </AnimatePresence>
  );
};

export default HomepageTabs;
