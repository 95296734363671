import React from "react";
import "./index.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";

import * as yup from "yup";

import { useEffect } from "react";
import { apiCallerPostLogin } from "../../../../services/api";

const ResetPassword = () => {
  const [params] = useSearchParams();

  const nav = useNavigate();
  const name = params.get("name");

  useEffect(() => {
    if (!name) nav("/login/forgotpassword");
  }, [name, nav]);

  const handleFormSubmit = async (value, actions) => {
    console.log(actions);
    try {
      const res = await apiCallerPostLogin({
        new_password: value.password,
        v_code: value.code,
        name,
        cmd: "UserPassword.checkVerificationCode",
      });

      if (res.data.code == 0) {
        nav("/login");
      } else {
        actions.setFieldError("general", res.data.msg);
      }
    } catch (error) {
      window.alert(error.message);
    }
  };

  const schema = yup.object({
    code: yup
      .string()
      .required("Verification code is required")
      .label("Verification code"),
    password: yup.string().required("Password is required").label("Password"),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Confirm password must match the password"
      )
      .required("Repeat password")
      .label("Confirm password"),
  });

  return (
    <>
      <div className="forgot-password-container mx-auto flex flex-col">
        {/* Back Button */}
        <Link to="/" className="back-button">
          <img src="/images/icon-back.png" alt="" srcset="" />
        </Link>

        {/* Heading */}
        <h1 className="reset-form-heading uppercase text-center mb-4">
          Reset Password
        </h1>

        {/* Box Content Container */}
        <main className="login-main flex-col flex items-center justify-center">
          <Formik
            initialValues={{ password: "", confirmPassword: "", code: "" }}
            validationSchema={schema}
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched, handleSubmit }) => (
              <Form
                onKeyDown={(event) =>
                  event.key === "Enter" && handleSubmit(event)
                }
                className="w-full space-y-3 login-form flex justify-between flex-col"
              >
                <Field
                  type="text"
                  name="code"
                  placeholder="Verification Code"
                  className="w-full input-field"
                  required
                />
                {errors.code && touched.code && (
                  <div
                    style={{ marginTop: "-2px" }}
                    className=" text-xl text-red-700"
                  >
                    {errors.code}
                  </div>
                )}
                <div className="flex flex-row space-x-4">
                  <div className="w-full">
                    <Field
                      type="password"
                      name="password"
                      placeholder="New Password"
                      className="w-full input-field"
                    />
                    {errors.password && touched.password && (
                      <div
                        style={{ marginTop: "-2px" }}
                        className=" text-xl text-red-700"
                      >
                        {errors.password}
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className="w-full input-field"
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div
                        style={{ marginTop: "-2px" }}
                        className=" text-xl text-red-700"
                      >
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                </div>
                {errors.general && (
                  <div className=" text-red-600  capitalize text-3xl">
                    {errors.general}.
                  </div>
                )}
                <button
                  type="submit"
                  className="btn-americau green-btn w-full uppercase"
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </main>

        {/* Footer Container */}
        <div className="bottom-container flex justify-between items-end">
          <a href="https://heroesleaguegameday.com/">
            {" "}
            <h1 className="learn-more-heading uppercase">Learn More</h1>{" "}
          </a>
          <div className="flex flex-col items-center">
            <p className="dont-have-account uppercase">
              Don't have an account?
            </p>
            <a href="https://portal.americau.com/" target={"_self"}>
              <h1 className="signup-text uppercase">Sign Up</h1>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
