import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "../store/slices/user";
import { loginUsingLocalCookie, loginUsingLocalStorage } from "../lib";
import { useState } from "react";
import Spinner from "../Components/Shared/Spinner/Spinner";

export default function AuthHandler({ children }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const checkForLogin = async () => {
    try {
      setLoading(true);

      if (process.env.NODE_ENV === "production") {
        const newUserFromCookies = await loginUsingLocalCookie();

        if (newUserFromCookies) {
          setLoading(false);
          return dispatch(login(newUserFromCookies));
        }
      }

      if (process.env.NODE_ENV === "development") {
        const user = loginUsingLocalStorage();

        if (user) {
          dispatch(login(user));
        }
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkForLogin();
  }, []);

  if (loading)
    return (
      <div className=" absolute w-screen h-screen bg-white flex justify-center items-center">
        <Spinner />
      </div>
    );

  return <React.Fragment>{children}</React.Fragment>;
}
