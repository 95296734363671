import React from "react";
import "./VideosPage.css";
import MobileHeader from "../MobileHeader/MobileHeader";
import PageTitle from "../PageTitle/PageTitle";
import { useSelector } from "react-redux";
import { getVideos } from "../../../store/slices/videos";

import Carousel from "react-elastic-carousel";
import { useRef } from "react";
import className from "classnames";
import ReactPlayer from "react-player";
import { sendWoogiEvent } from "../../../services/api";

function MyArrow({ type, onClick, isEdge }) {
  return (
    <button
      disabled={isEdge}
      onClick={onClick}
      className={className("slide-btn self-center", {
        "-rotate-180": type == "NEXT",
      })}
      style={{
        background: "url(images/icon-back.png)",
      }}
    />
  );
}

const VideosPage = () => {
  const videos = useSelector(getVideos);
  const ref = useRef();

  return (
    <div className="videosPage-container">
      <MobileHeader />
      <PageTitle title="Videos" />
      <div className="vidoesContent-container relative">
        <div className="flex flex-row relative w-full items-center">
          <div className="videos-row w-full relative">
            <Carousel
              renderArrow={MyArrow}
              ref={ref}
              itemsToShow={3}
              pagination={false}
            >
              {videos.map((video, index) => (
                <div className="flex flex-col w-full">
                  <h1 className="videos-title">{video.name}</h1>
                  <div
                    className="player-wrapper"
                    onClick={() => {
                      sendWoogiEvent("VIDEO_PLAY_VIDEOS");
                    }}
                  >
                    <ReactPlayer
                      className="react-player"
                      width="90%"
                      height="100%"
                      controls
                      url={video.video_url}
                      onEnded={() => {
                        sendWoogiEvent("VIDEO_FINISH_VIDEO");
                      }}
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideosPage;
