import React, { useContext } from "react";
import "./Navmenu.css";
import { NavLink } from "react-router-dom";
import { GlobalInfo } from "../../../App";
import { sendWoogiEvent } from "../../../services/api";

const Navmenu = () => {
  const { sidebar, HideSidebar } = useContext(GlobalInfo);
  return (
    <nav className="nav-bar">
      <ul className="nav-NavLinks-container">
        <li>
          <NavLink
            onClick={() => {
              HideSidebar(sidebar);
              sendWoogiEvent("BUTTON_NAV_HOME");
            }}
            to="/index"
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => {
              HideSidebar(sidebar);
              sendWoogiEvent("BUTTON_NAV_NEWS");
            }}
            to="/news"
          >
            News
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => {
              HideSidebar(sidebar);
              sendWoogiEvent("BUTTON_NAV_STORY_COMICS");
            }}
            to="/seasonpass"
          >
            Stories
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => {
              HideSidebar(sidebar);
              sendWoogiEvent("BUTTON_NAV_EVENTS");
            }}
            to="/events"
          >
            Events
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => {
              HideSidebar(sidebar);
              sendWoogiEvent("BUTTON_NAV_VIDEOS");
            }}
            to="/videos"
          >
            Videos
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => {
              HideSidebar(sidebar);
              sendWoogiEvent("BUTTON_OPEN_MINI_GAME");
            }}
            to="/games"
          >
            Games
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => {
              HideSidebar(sidebar);
              sendWoogiEvent("BUTTON_OPEN_COMIC_VAULT");
            }}
            to="/comics"
          >
            Comics
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => {
              HideSidebar(sidebar);
              sendWoogiEvent("BUTTON_NAV_LEADERBOARD");
            }}
            to="/leaderboard"
          >
            LeaderBoard
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => {
              HideSidebar(sidebar);
              sendWoogiEvent("BUTTON_NAV_BLOG");
            }}
            to="/blog"
          >
            Blog
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navmenu;
