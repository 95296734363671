import { apiCallerPostLogin } from "../services/api";

export const isDev = process.env.NODE_ENV === "development";

export const loginUsingLocalStorage = () => {
  const localUser = localStorage.getItem("user");

  if (!localUser) return null;

  const user = JSON.parse(localUser);
  const jwt_str = JSON.parse(localStorage.getItem("jwt_str"));
  const access_token = JSON.parse(localStorage.getItem("access_token"));

  if (!jwt_str || !access_token) return null;

  return {
    user,
    jwt_str,
    access_token,
  };
};

export const loginUsingLocalCookie = async () => {
  const resp = await apiCallerPostLogin({
    cmd: "WoogiOAuth.getPortalUserObj",
  });

  if (resp.data.code == 102) {
    return hardLogout();
  }

  const user = resp.data?.jwt_info?.user;
  const jwt_str = resp.data?.jwt_str;
  const access_token = resp.data?.access_token;

  localStorage.setItem("access_token", JSON.stringify(access_token));
  localStorage.setItem("jwt_str", JSON.stringify(jwt_str));
  localStorage.setItem(
    "user",
    JSON.stringify({
      uid: user.uid,
      id: user.id,
      login_name: user.login_name,
      nickname: user.nickname,
      ...user,
    })
  );

  return {
    user: {
      uid: resp.data.jwt_info.user.uid,
      id: resp.data.jwt_info.user.id,
      login_name: resp.data.jwt_info.user.login_name,
      nickname: resp.data.jwt_info.user.nickname,
      ...resp.data?.jwt_info?.user,
    },
    jwt_str: resp.data?.jwt_str,
    access_token: resp.data?.access_token,
  };
};

export const hardLogout = () => {
  localStorage.clear();
  removeAllCookies();
};

function removeAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim().split("=");
    deleteCookie(cookie[0]);
  }
}

function deleteCookie(cookieName) {
  document.cookie =
    cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export const comicLink = (comic) => {
  const isDev = process.env.NODE_ENV === "development";

  return isDev
    ? // ? `https://portal.americau.com/comic/read?comic_id=${comic.id}`
      `https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe`
    : `/woogi/0.1/static/leaderboard/v3/comic/read.php?comic_id=${comic.id}`;
};
