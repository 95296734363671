import React, { useContext } from "react";
import "./Sidebar.css";
import Navmenu from "./Navmenu/Navmenu";
import { GlobalInfo } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import { sendWoogiEvent } from "../../services/api";
import { useSelector } from "react-redux";
import { getUser } from "../../store/slices/user";

const Sidebar = () => {
  const { sidebar, HideSidebar } = useContext(GlobalInfo);
  const navigate = useNavigate();
  const authenticatedUser = useSelector(getUser);

  const role = authenticatedUser?.user?.role;

  const navToData = () => {
    try {
      window.location.href =
        "https://mdashboard.woogiu.com/?token=" +
        authenticatedUser.access_token;
    } catch (error) {
      console.log(error);
    }
  };

  const handleMeetHeroClick = () => {
    HideSidebar(sidebar);
    navigate("/meet-the-heroes");
    sendWoogiEvent("BUTTON_NAV_MEET_HEROES");
  };

  return (
    <>
      <div
        style={sidebar ? { display: "flex" } : {}}
        className="sidebar-container"
      >
        <div className="logo-nav-container">
          <div className="logo-container">
            <Link onClick={() => HideSidebar(sidebar)} to="/index">
              <img className="logo-img" src={"logo.png"} alt="" />{" "}
            </Link>
          </div>
          <Navmenu />
        </div>

        <div className="sidebar-footer-container cursor-pointer">
          <button
            onClick={handleMeetHeroClick}
            className="meet-heroes-img-container"
            style={{ backgroundImage: "url(images/meethHeroes.jpg)" }}
          />
          {role > 0 && (
            <button className="portal-container" onClick={navToData}>
              Parent/Teacher Login
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
