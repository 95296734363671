import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { getUser, updateUser } from "../../../store/slices/user";
import { apiCallerPost } from "./../../../services/api";
import { loadNews } from "../../../store/slices/news";
import { loadSeasons, loadStories } from "../../../store/slices/season";
import { loadEvents } from "../../../store/slices/events";
import { loadVideos } from "../../../store/slices/videos";
import { loadGames } from "../../../store/slices/games";
import { loadComics } from "../../../store/slices/comics";
import { loadBlog } from "../../../store/slices/blog";
import { loadWhatsnew } from "../../../store/slices/WhatsNew";
import { loadGiftModalStatus } from "../../../store/slices/gifts";

import { useState } from "react";

import Spinner from "../Spinner/Spinner";
import { loadUiData } from "../../../store/slices/ui";
import { loaded } from "../../../store/slices/loader";

export default function DataLoader({ children }) {
  const auth = useSelector(getUser);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userId = auth?.user?.uid;

  const fetchData = async () => {
    const [
      news,
      season,
      events,
      videos,
      games,
      comics,
      blog,
      stories,
      whatsNew,
      gifts,
      showNameAndPwd,
      skillRadar,
      nickname,
    ] = await Promise.all([
      apiCallerPost({
        cmd: "auth_HeroLeaguePortalIndex.getNews",
        limit: 20,
      }),
      apiCallerPost({ cmd: "auth_HeroLeaguePortalIndex.getCurSeason" }),
      apiCallerPost({ cmd: "auth_HeroLeaguePortalIndex.getEvents" }),
      apiCallerPost({ cmd: "auth_HeroLeaguePortalIndex.getVideos" }),
      apiCallerPost({ cmd: "auth_HeroLeaguePortalIndex.getMiniGames" }),
      apiCallerPost({ cmd: "auth_HeroLeaguePortalIndex.getComics" }),
      apiCallerPost({ cmd: "auth_HeroLeagueBlogOpen.getBlog" }),
      apiCallerPost({
        cmd: "auth_HeroLeaguePortalIndex.getCurSeasonStory",
      }),
      apiCallerPost({ cmd: "awc_portal_WhatsNews.getNews" }),
      apiCallerPost({
        cmd: "GetGifts.userReceivedGifts",
        user_id: userId,
      }),
      apiCallerPost({
        cmd: "auth_HeroLeaguePortalIndex.showNameAndPwd",
        user_id: userId,
      }),
      apiCallerPost({
        cmd: "auth_HeroLeaguePortalIndex.showSkillRadar",
      }),
      apiCallerPost({
        cmd: "auth_HeroLeaguePortalIndex.getNickname",
      }),
    ]);

    dispatch(loadNews(news.data.data));
    dispatch(loadSeasons(season.data.data));
    dispatch(loadEvents(events.data.data));
    dispatch(loadVideos(videos.data.data));
    dispatch(loadGames(games.data.data));
    dispatch(loadComics(comics.data.data));
    dispatch(loadBlog(blog.data.blogs));
    dispatch(loadStories({ stories: stories.data.data }));
    dispatch(loadWhatsnew(whatsNew.data.data));
    dispatch(loadGiftModalStatus(gifts.data?.received_gifts || false));
    dispatch(
      loadUiData({
        passwordModal: showNameAndPwd.data,
        skillRadar: skillRadar.data,
      })
    );
    dispatch(updateUser({ nickname: nickname.data }));
  };

  useEffect(() => {
    (async () => {
      if (userId) {
        setLoading(true);
        await fetchData();
        setLoading(false);
        dispatch(loaded(true));
      }
      console.log("userId", userId);
    })();
  }, [userId]);

  if (loading)
    return (
      <div className=" absolute w-screen h-screen bg-white flex justify-center items-center">
        <Spinner />
      </div>
    );

  return children;
}
