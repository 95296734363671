import React, { useEffect } from "react";
import Comment from "./Comment";
import { useState } from "react";
import { apiCallerPost } from "../../../../services/api";
import CommentForm from "../CommentForm";

export default function CommentsList({ post }) {
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const getComments = async () => {
      const results = await apiCallerPost({
        cmd: "auth_HeroLeagueBlogOpen.getPortalBlogComment",
        blog_id: post.id,
      });

      setComments(results.data?.comments || []);
      setLoading(false);
    };

    if (post?.id) getComments();
  }, [post?.id, loading]);

  const handleSubmitComment = async (e) => {
    setLoading(true);

    const resp = await apiCallerPost({
      cmd: "auth_HeroLeagueBlogOpen.addComment",
      blog_id: post.id,
      content: description,
    });

    if (resp.data.code === 0) {
      setDescription("");
      setComments((old) => [resp.data, ...old]);
    }

    setLoading(false);
  };

  return (
    <div className="blogComment-container">
      <h2 className="commentsCount">
        {comments?.length ? `${comments?.length + " Comments"}` : "No Comments"}
      </h2>

      {/* Comment List */}
      <div className="commentList-container">
        {comments?.map((comment) => (
          <Comment post={post} key={comment.id} comment={comment} />
        ))}
      </div>

      <CommentForm
        value={description}
        onChange={setDescription}
        loading={loading}
        onSubmit={handleSubmitComment}
      />
    </div>
  );
}
