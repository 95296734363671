import { createSelector, createSlice } from "@reduxjs/toolkit";

const ui = createSlice({
  name: "ui",
  initialState: { passwordModal: 0, skillRadar: 0 },
  reducers: {
    loadUiData(state, action) {
      return {
        ...state,
        passwordModal: action.payload.passwordModal,
        skillRadar: action.payload.skillRadar,
      };
    },
  },
});

export const { loadUiData } = ui.actions;

const passwordModalStatus = (state) => {
  return state.ui.passwordModal;
};

const skillRadarStatus = (state) => state.ui.skillRadar;

export const getSkillRadarStatus = createSelector(
  skillRadarStatus,
  (status) => status
);

export const getPasswordModalStatus = createSelector(
  passwordModalStatus,
  (status) => status
);

export default ui.reducer;
