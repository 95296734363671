import { createSelector, createSlice } from "@reduxjs/toolkit";

const newsSlice = createSlice({
  name: "gifts",
  initialState: {
    receivedGifts: false,
  },
  reducers: {
    loadGiftModalStatus(state, action) {
      return { ...state, receivedGifts: action.payload };
    },
    disbleModal(state, action) {
      return { ...state, receivedGifts: false };
    },
  },
});

export const { disbleModal, loadGiftModalStatus } = newsSlice.actions;

const gifts = (state) => state.gifts;

export const isGiftModalShown = createSelector(
  gifts,
  (gifts) => gifts.receivedGifts
);

export default newsSlice.reducer;
