import React from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../../../../store/slices/user";
import "./MyHero.css";

import { getSkillRadarStatus } from "../../../../../store/slices/ui";
import { useContext } from "react";
import { GlobalInfo } from "../../../../../App";

const MyHero = (props) => {
  const { MyHeroTabActive } = props;
  const auth = useSelector(getUser);
  const skillRadarStatus = useSelector(getSkillRadarStatus);
  const { setSkillRadarPortal } = useContext(GlobalInfo);

  return (
    <div
      style={
        MyHeroTabActive === 1
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
          : { display: "none" }
      }
      className=""
    >
      <div className="dailyChalleng-box">
        <p className="charcter-name">{auth?.user?.nickname}</p>
        {skillRadarStatus != 0 && (
          <button
            onClick={() => setSkillRadarPortal(auth)}
            className="skill-dev-btn"
          >
            Skill Radar
          </button>
        )}
      </div>
    </div>
  );
};

export default MyHero;
