import React, { useState } from "react";
import "./News.css";
import MobileHeader from "../MobileHeader/MobileHeader";
import PageTitle from "../PageTitle/PageTitle";
import { useSelector } from "react-redux";
import { getNews } from "../../../store/slices/news";
import Slider from "react-slick";
import Carousel from "react-elastic-carousel";
import { useRef } from "react";

const News = () => {
  const news = useSelector(getNews);
  const [setselectedNews, setSetselectedNews] = useState(news[0]);
  const ref = useRef();

  // Mobile Slider Settings
  const [mobileSliderRef, setMobileSliderRef] = useState(null);
  const mobileSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "20px",
  };

  return (
    <div className="newsPage-container">
      <MobileHeader />
      <PageTitle title="News" />
      <div className="newspg-main">
        <div className="newspg-content-container">
          <div className="newspg-main-imgcontainer">
            <img
              className="newspg-main-img"
              src={setselectedNews.attachment[0]}
              alt="news main thumbnail"
            />
          </div>
          <div className="newspg-description-container">
            <h1 className="blogListing-title">{setselectedNews.title}</h1>
            <p className="newspg-dscrp">{setselectedNews.content}</p>
          </div>
        </div>
        <div className="moreNews-sidebar-container">
          <img
            className="moreNews-sidebar-img"
            src="images/moreNewsSidebar.png"
            alt=""
          />
          <div className="moreNews-listContainer">
            <h3 className="morenews-title">More</h3>
            <div className="moreNewsitemContainer text-center flex-col ">
              <button
                onClick={() => ref.current.slidePrev()}
                className="slide-btn absolute rotate-90 z-10 -top-5 -ml-6 "
                style={{
                  background: "url(images/icon-back.png)",
                }}
              />
              <Carousel
                ref={ref}
                disableArrowsOnEnd
                pagination={false}
                showArrows={false}
                verticalMode
                itemsToShow={3}
              >
                {news.map((latestNews, index) => (
                  <img
                    key={latestNews.id}
                    onClick={() => setSetselectedNews(latestNews)}
                    className="moreNewsImg cursor-pointer"
                    src={latestNews.attachment[0]}
                    alt={latestNews.title}
                  />
                ))}
              </Carousel>

              <button
                onClick={() => ref.current.slideNext()}
                className="slide-btn absolute -rotate-90 -mt-8 -ml-5"
                style={{
                  background: "url(images/icon-back.png)",
                }}
              />
            </div>
          </div>
        </div>

        {/* More News Mobile Slider */}
        <div className="mobile-moreNews-slider">
          <img
            onClick={mobileSliderRef?.slickNext}
            className="moreNews-sidePrevIcon"
            src="images/icon-back.png"
            alt=""
          />
          <img
            className="moreNews-slider-img"
            src="images/moreNews-bg.png"
            alt=""
          />
          <div className="moblNewsSliderContainer">
            <Slider ref={setMobileSliderRef} {...mobileSettings}>
              {news &&
                news.map((latetNews) => (
                  <img
                    className="mobileSlide-newsImg"
                    src={latetNews.attachment[0]}
                    alt=""
                  />
                ))}
            </Slider>
          </div>
          <img
            onClick={mobileSliderRef?.slickPrev}
            className="moreNews-sideNxtIcon"
            src="images/icon-back.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default News;
