import React, { Fragment, useState } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";

import Spinner from "./../../../Shared/Spinner/Spinner";
import { apiCallerPostLogin } from "../../../../services/api";

const ForgotPassword = () => {
  const [value, setValue] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState("");

  const nav = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!value) return setError("Please enter username.");

    setSpinner(true);

    try {
      const res = await apiCallerPostLogin({
        cmd: "UserPassword.emailFindBackAccount",
        name: value,
      });

      console.log(res);

      if (res.data.code != 0) {
        return setError(res.data.msg);
      }

      setSent(true);
    } catch (error) {
      console.log(error);
    }

    setSpinner(false);
  };

  return (
    <>
      <div className="forgot-password-container mx-auto flex flex-col">
        {/* Back Button */}
        <Link to="/" className="back-button">
          <img src="/images/icon-back.png" alt="Back icon" srcset="" />
        </Link>

        {/* Heading */}
        <div className="mb-4 text-center">
          <h1 className="heading-container reset-form-heading uppercase text-center mb-4">
            Reset Password
          </h1>
          {!sent && (
            <h2
              style={{ color: "#002356" }}
              className="text-4xl normal-case reset-pwd-info"
            >
              Enter your username to receive an email to reset your password
            </h2>
          )}
        </div>

        {/* Box Content Container */}
        <main className="login-main  flex-col flex items-center justify-center">
          {!sent && (
            <form
              onKeyDown={(event) =>
                event.key === "Enter" && handleFormSubmit(event)
              }
              onSubmit={handleFormSubmit}
              className="w-full reset-form login-form flex justify-between flex-col items-center"
            >
              {spinner && <Spinner />}
              {!spinner && (
                <Fragment>
                  <input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    name="Username"
                    type="text"
                    placeholder="Username"
                    className="w-full input-field mb-3"
                    onBlur={() => {
                      if (value.length > 2) setError("");
                    }}
                  />
                  {error && (
                    <div className=" w-full text-left text-red-700 text-3xl mb-4">
                      {error}
                    </div>
                  )}

                  <button
                    type="submit"
                    className="btn-americau green-btn w-full uppercase"
                  >
                    Reset
                  </button>
                </Fragment>
              )}
            </form>
          )}

          {sent && (
            <Fragment>
              <img src="/images/check.gif" alt="Email sent" />
              <h1 className="text-3xl text-center mb-4">
                Email has been sent. Please check your inbox(spam)
              </h1>

              <button
                onClick={() => {
                  nav(`/login/update-password?name=${value}`);
                }}
                class="btn-americau green-btn w-full uppercase"
              >
                Enter Code
              </button>
            </Fragment>
          )}
        </main>

        {/* Footer Container */}
        {!sent && (
          <div className="bottom-container flex justify-between items-end">
            <a href="https://heroesleaguegameday.com/">
              {" "}
              <h1 className="learn-more-heading uppercase">Learn More</h1>{" "}
            </a>
            <div className="flex flex-col items-center">
              <p className="dont-have-account uppercase">
                Don't have an account?
              </p>
              <a href="/">
                <h1 className="signup-text uppercase">Sign Up</h1>
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ForgotPassword;
